import React, { FunctionComponent } from 'react';
import {
    Typography,
    Grid,
    TextField,
    ThemeProvider,
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from '@material-ui/core';
import styles, { referralTransportationSummaryTheme } from './ReferralTransportationSummary.Styles';
import { EmptyDataLabel } from '../../../../generic-components/empty-data-label/EmptyDataLabel.Component';
import Moment from 'moment';
import TransportationDataModel from '../../../../../data-models/Transportation.DataModel';
import AppointmentTypeEntity from '../../../../../entities/AppointmentType.Entity';
import DestinationEntity from '../../../../../entities/Destination.Entity';
import OriginationEntity from '../../../../../entities/Origination.Entity';
import TransportationRequiredEntity from '../../../../../entities/TransportationRequired.Entity';

interface IReferralTransportationSummaryProps {
    transportationService: TransportationDataModel;
    appointmentTypes: Array<AppointmentTypeEntity>;
    destinations: Array<DestinationEntity>;
    originations: Array<OriginationEntity>;
    transportationRequireds: Array<TransportationRequiredEntity>;
}

export const ReferralTransportationSummary: FunctionComponent<IReferralTransportationSummaryProps> = (
    props: IReferralTransportationSummaryProps,
) => {
    const classes = styles();

    return (
        <Accordion defaultExpanded={true} className={classes.paper} square>
            <AccordionSummary aria-controls="panel1a-content" id="cliam-accordion" className={classes.paperHeader}>
                <Typography variant="subtitle2" className={classes.paperTitle}>
                    Transportation
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                {props.transportationService ? (
                    <Grid container spacing={3} className={classes.gridContainer}>
                        <Grid xs={12} item container>
                            <Grid item xs={2}>
                                <Typography variant="body2" className={classes.dataLabel}>
                                    Transportation Required
                                </Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>
                                    {!!props.transportationService.serviceTypeRequestTransportation
                                        ?.transportationRequiredId
                                        ? props.transportationRequireds?.filter(
                                              (x) =>
                                                  props.transportationService.serviceTypeRequestTransportation &&
                                                  props.transportationService.serviceTypeRequestTransportation
                                                      .transportationRequiredId &&
                                                  x.transportationRequiredId ==
                                                      props.transportationService.serviceTypeRequestTransportation
                                                          .transportationRequiredId,
                                          )[0]?.transportationRequiredName
                                        : '-'}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="body2" className={classes.dataLabel}>
                                    Other Transportation Required
                                </Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>
                                    {props.transportationService.serviceTypeRequestTransportation
                                        ?.otherTransportationRequired
                                        ? props.transportationService.serviceTypeRequestTransportation
                                              ?.otherTransportationRequired
                                        : '-'}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="body2" className={classes.dataLabel}>
                                    Appointment Date
                                </Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>
                                    {Moment(
                                        props.transportationService.serviceTypeRequestTransportation?.appointmentDate,
                                    ).format('MM/DD/YYYY')}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>                                
                                <Typography variant="body2" className={classes.dataLabel}>
                                    Frequency and Duration
                                </Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>
                                    {props.transportationService.serviceTypeRequestTransportation
                                        ?.frequencyAndDuration
                                        ? props.transportationService.serviceTypeRequestTransportation
                                              ?.frequencyAndDuration
                                        : ''}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid xs={12} item container>
                            <ThemeProvider theme={referralTransportationSummaryTheme}>
                                <TextField
                                    className={classes.summaryTextArea}
                                    multiline
                                    disabled
                                    label="Special Instructions"
                                    id="specialInstructions"
                                    defaultValue={
                                        props.transportationService.serviceTypeRequestTransportation
                                            ?.specialInstructions
                                            ? props.transportationService.serviceTypeRequestTransportation
                                                  ?.specialInstructions
                                            : ''
                                    }
                                />
                            </ThemeProvider>
                            <Grid />
                        </Grid>
                    </Grid>
                ) : (
                    <EmptyDataLabel></EmptyDataLabel>
                )}
            </AccordionDetails>
        </Accordion>
    );
};
