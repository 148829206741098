import React, { Fragment, FunctionComponent, useEffect } from 'react';
import styles from './TranslationHeader.Styles';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    MenuItem,
    Radio,
    RadioGroup,
    Typography,
} from '@material-ui/core';
import { EmptyDataLabel } from '../../../../../generic-components/empty-data-label/EmptyDataLabel.Component';
import { TooltipTextField } from '../../../../../generic-components/tooltip-textfield/TooltipTextField.Component';
import ServiceTypeEntity from '../../../../../../entities/ServiceType.Entity';
import { useValidationDialog } from '../../../../../../support/custom-hooks/useValidationDialog';
import AppointmentTypeEntity from '../../../../../../entities/AppointmentType.Entity';
import Moment from 'moment';
import ServiceTypeRequestTranslationEntity from '../../../../../../entities/ServiceTypeRequestTranslation.Entity';
import LanguageEntity from '../../../../../../entities/Language.Entity';
import InterpretationTypeEntity from '../../../../../../entities/InterpretationType.Entity';
import TextfieldMask from '../../../../../generic-components/texfield-mask/TextfieldMask.Component';
import { ITtReferralFormErrors } from '../TtReferralForm.Component';
import StateEntity from '../../../../../../entities/State.Entity';
import CloseIcon from '@material-ui/icons/Close';
import SearchAddressAsync, { AddressValue } from '../../../../../../components/generic-components/search-autocomplete/SearchAddressAsync'
import AddressAutocompleteEntity from '../../../../../../entities/ReferralAddress.Entity';


interface ITranslationHeaderProps {
    referralId: number;
    appointmentTypes: Array<AppointmentTypeEntity>;
    interpretationTypes: Array<InterpretationTypeEntity>;
    errors: ITtReferralFormErrors;
    validate: (isChange?: boolean, isSubmission?: boolean) => void;
    hasBeenSubmitted: boolean;
    handleSetValidationFunction: (currentPageValidationFunction: () => boolean) => void;
    serviceTypes: Array<ServiceTypeEntity>;
    serviceTypeRequestTranslation: ServiceTypeRequestTranslationEntity;
    handleServiceTypeRequestTranslationEdit: any;
    languages: Array<LanguageEntity>;
    states: Array<StateEntity>;
}

export interface ITranslationHeaderErrors {
    //row 1
    tlAppointmentType?: string;
    tlAppointmentDate?: string;

    //row 4
    language?: string;

    //row 5
    translationType?: string;
    tlFrequencyAndDuration?: string;
}

export const TranslationHeader: FunctionComponent<ITranslationHeaderProps> = (props: ITranslationHeaderProps) => {
    const classes = styles();
    const abortController = new AbortController();

    const languages = [...props?.languages??[], { languageId: 0, languageName: 'Other' }];
    const appointmentTypes = [...props?.appointmentTypes??[], { appointmentTypeId: 0, appointmentTypeName: 'Other' }];

    const [serviceTypeRequestTranslation, setServiceTypeRequestTranslation] = React.useState<
        ServiceTypeRequestTranslationEntity
    >(props.serviceTypeRequestTranslation);

    const [counterState, setCounterState] = React.useState(
        props.serviceTypeRequestTranslation ? props.serviceTypeRequestTranslation : null,
    );

    const [localServiceTypes, setLocalServiceTypes] = React.useState<Array<ServiceTypeEntity>>(null);
    const [errors, setErrors] = React.useState<ITranslationHeaderErrors>(null);
    const { Dialog, checkForErrors } = useValidationDialog('Validation', 'Some required fields are missing.', 'Ok');
    const [translationFullAddress, setTranslationFullAddress] = React.useState("");

    const passAddress = (address:AddressAutocompleteEntity) => {

        let newTranslationRequest: ServiceTypeRequestTranslationEntity = serviceTypeRequestTranslation;
        newTranslationRequest["city"] = address.city;
        newTranslationRequest["appointmentAddress"] = `${address.streetNumber? address.streetNumber:''} ${address.streetName} ${address.address2 ?? ""}`.trim();
        newTranslationRequest["zipCode"] = address.zipCode;

        const stateItem = props.states?.find(element => element.stateName==address.stateName);
        newTranslationRequest["stateId"] = stateItem?.stateId;
        
        setTranslationFullAddress(address.fullAddress);  
        setServiceTypeRequestTranslation(newTranslationRequest);
      }

      const handleClear = ()=>{
        
        let newTranslationRequest: ServiceTypeRequestTranslationEntity = serviceTypeRequestTranslation
        newTranslationRequest["city"] = undefined
        newTranslationRequest["appointmentAddress"] = undefined
        newTranslationRequest["zipCode"] = undefined
        newTranslationRequest["stateId"] = undefined;

        setTranslationFullAddress("");
        setServiceTypeRequestTranslation(newTranslationRequest);
    
    }

    //componentDidMount
    useEffect(() => {
        if (props.serviceTypeRequestTranslation) {
            const newSTTR: ServiceTypeRequestTranslationEntity = props.serviceTypeRequestTranslation;
            const translationTypes = props.serviceTypes.filter(x => x.serviceTypeId!=51);
            newSTTR.translationType = translationTypes.length > 0 ? translationTypes[0].serviceTypeName:null;
            setServiceTypeRequestTranslation(newSTTR);
            setCounterState(newSTTR);
        } else {
            const newSTTR: ServiceTypeRequestTranslationEntity = new ServiceTypeRequestTranslationEntity();
            newSTTR.referralId = props.referralId;
            setServiceTypeRequestTranslation(newSTTR);
            setCounterState(newSTTR);
        }

        if (!localServiceTypes && props.serviceTypes) {
            setLocalServiceTypes(props.serviceTypes);
        }

        return function cleanup() {
            abortController.abort();
        };
    }, [props]);

    useEffect(() => {
        if(serviceTypeRequestTranslation?.stateId)
        {
            const stateItem = props.states?.find(element => element.stateId==serviceTypeRequestTranslation.stateId);
            
            setTranslationFullAddress(MakeTranslationFullAddress(serviceTypeRequestTranslation,stateItem.stateName));
        }
    }, [serviceTypeRequestTranslation]);

    const MakeTranslationFullAddress = (tranlationState : ServiceTypeRequestTranslationEntity,stateName:string) =>
    `${ tranlationState.appointmentAddress } ${tranlationState.city?tranlationState.city+", ":""} ${stateName? `${stateName} `:"" }${ tranlationState.zipCode? tranlationState.zipCode:""}`

    const handleServiceTypeRequestTranslation = (event) => {
        event.persist();

        const newSTTR: ServiceTypeRequestTranslationEntity = serviceTypeRequestTranslation;

        if (!newSTTR.referralId) {
            newSTTR.referralId = props.referralId;
        }

        if (event.target.type == 'checkbox') {
            setCounterState({
                ...counterState,
                [event.target.id ? event.target.id : event.target.name]: event.target.checked,
            });
            newSTTR[event.target.id ? event.target.id : event.target.name] = event.target.checked;
        } else {
            setCounterState({
                ...counterState,
                [event.target.id ? event.target.id : event.target.name]: event.target.value,
            });
            newSTTR[event.target.id ? event.target.id : event.target.name] =
                event.target.value == 'true'
                    ? true
                    : event.target.value == 'false'
                    ? false
                    : event.target.value?.toString() == 'true'
                    ? true
                    : event.target.value?.toString() == 'false'
                    ? false
                    : event.target.value;
        }

        if (newSTTR.languageId !== 0) {
            newSTTR.otherLanguage = null;
        }
        if (newSTTR.appointmentTypeId !== 0) {
            newSTTR.otherAppointmentType = null;
        }

        setServiceTypeRequestTranslation(newSTTR);

        props.handleServiceTypeRequestTranslationEdit(serviceTypeRequestTranslation);

        if (props.hasBeenSubmitted) props.validate(true, false);
    };
    const divStyle = {
        display: 'flex',
        alignItems: 'left',
        borderBottom:"1px #E7EEF1 solid"
      };
      
      const minutesAppointment = [15,30,45,60,75,90,105,120];

    return (
        <Fragment>
            {localServiceTypes ? (
                <Grid container spacing={3} className={classes.gridContainer}>
                    {/* Row 1 */}
                    <Grid item xs={3} container>
                        <Grid item xs={12}>
                            <TooltipTextField
                                type="select"
                                formControlClass={classes.selectInput}
                                label="Appointment Type"
                                inputLabelId="demo-simple-select-label"
                                id="appointmentTypeId"
                                name="appointmentTypeId"
                                key={serviceTypeRequestTranslation?.appointmentTypeId}
                                value={
                                    serviceTypeRequestTranslation?.appointmentTypeId ||
                                    serviceTypeRequestTranslation?.appointmentTypeId === 0
                                        ? serviceTypeRequestTranslation?.appointmentTypeId
                                        : ''
                                }
                                onChange={(event) => handleServiceTypeRequestTranslation(event)}
                                zeroIsValid
                                required
                                error={!!props.errors?.tlAppointmentType}
                            >
                                {appointmentTypes.map((appointmentType, index) => {
                                    return (
                                        <MenuItem
                                            key={appointmentType.appointmentTypeId}
                                            value={appointmentType.appointmentTypeId}
                                        >
                                            {appointmentType.appointmentTypeName}
                                        </MenuItem>
                                    );
                                })}
                            </TooltipTextField>
                            <FormHelperText>{props?.errors?.tlAppointmentType}</FormHelperText>
                        </Grid>
                    </Grid>
                    <Grid item xs={3}>
                        <TooltipTextField
                            label="Other Appointment Type"
                            id="otherAppointmentType"
                            onChange={(event) => handleServiceTypeRequestTranslation(event)}
                            disabled={
                                !!serviceTypeRequestTranslation.appointmentTypeId ||
                                serviceTypeRequestTranslation.appointmentTypeId !== 0
                            }
                            value={
                                serviceTypeRequestTranslation?.otherAppointmentType
                                    ? serviceTypeRequestTranslation?.otherAppointmentType
                                    : ''
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TooltipTextField
                            label="Appointment Date"
                            id="appointmentDate"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            defaultValue={
                                serviceTypeRequestTranslation.appointmentDate
                                    ? Moment(serviceTypeRequestTranslation.appointmentDate).format('YYYY-MM-DD')
                                    : ''
                            }
                            required
                            error={!!props.errors?.tlAppointmentDate}
                            onChange={(event) => handleServiceTypeRequestTranslation(event)}
                        />
                        <FormHelperText>{props?.errors?.tlAppointmentDate}</FormHelperText>
                    </Grid>
                    <Grid item xs={3}>
                        {/* empty space */}
                    </Grid>
                    {/* Row 2 */}

                    <Grid item xs={3}>
                        <TooltipTextField
                            label="Appointment Time"
                            id="appointmentTime"
                            type="time"
                            InputLabelProps={{ shrink: true }}
                            onChange={(event) => handleServiceTypeRequestTranslation(event)}
                            style={{
                                width: '93%',
                            }}
                            defaultValue={
                                typeof serviceTypeRequestTranslation?.appointmentTime === 'string'
                                    ? serviceTypeRequestTranslation?.appointmentTime?.split('T')[1]
                                    : serviceTypeRequestTranslation?.appointmentTime
                            }
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <TooltipTextField
                         type="select"
                         formControlClass={classes.selectInput}
                            label="Appointment Length (Min)"
                            id="appointmentLength"
                            onChange={(event) => handleServiceTypeRequestTranslation(event)}
                            inputLabelId="demo-simple-select-label"                                
                            name="appointmentLength"
                            key={serviceTypeRequestTranslation?.appointmentLength}
                            value={
                                serviceTypeRequestTranslation?.appointmentLength
                                    ? serviceTypeRequestTranslation?.appointmentLength
                                    : ''
                            }
                            >
                            {minutesAppointment?.map((minute, index) => {
                                return (
                                    <MenuItem
                                        key={minute}
                                        value={minute}
                                    >
                                       {minute}
                                    </MenuItem>
                                );
                            })}
                        </TooltipTextField>
                    </Grid>

                    <Grid item xs={3}>
                        <FormControlLabel
                            label={<Typography variant="body2">Rush Request</Typography>}
                            control={
                                <Checkbox
                                    key={serviceTypeRequestTranslation?.rushRequest == null ? 'false' : 'true'}
                                    id="rushRequest"
                                    name="rushRequest"
                                    disableRipple
                                    checked={serviceTypeRequestTranslation?.rushRequest}
                                    onClick={(event) => handleServiceTypeRequestTranslation(event)}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        {/* EMPTY SPACE */}
                    </Grid>
                    {/* Row 3 */}
                    <Grid item xs={3} container>
                        <Grid item xs={12}>                        
                            <SearchAddressAsync passAddress={passAddress} states={props.states} />
                        </Grid>
                    </Grid>
                    <Grid item xs={3} container>
                        <Grid item xs={12}>
                        <TextfieldMask
                            label="Appointment Contact Number"
                            id="appointmentContactNumber"
                            onChange={(event) => handleServiceTypeRequestTranslation(event)}
                            value={
                                serviceTypeRequestTranslation?.appointmentContactNumber
                                    ? serviceTypeRequestTranslation?.appointmentContactNumber
                                    : ''
                            }
                            mask="(999)-9999999"
                        />
                        </Grid>
                    </Grid>
                    <Grid item xs={3}>
                    <TooltipTextField
                            label="Frequency and Duration"
                            id="frequencyAndDuration"
                            onChange={(event) => handleServiceTypeRequestTranslation(event)}
                            value={
                                serviceTypeRequestTranslation?.frequencyAndDuration
                                    ? serviceTypeRequestTranslation?.frequencyAndDuration
                                    : ''
                            }
                            required
                            error={!!props.errors?.tlFrequencyAndDuration}
                        />
                        <FormHelperText>{props?.errors?.tlFrequencyAndDuration}</FormHelperText>
                    </Grid>
                    <Grid item xs={3}>
                    </Grid>
                    <Grid item xs={3} container>
                        <Grid item xs={12}>
                          <AddressValue 
                              key="TranslationSearchAddressResult" 
                              id="SearchAddressResult" 
                              address={translationFullAddress} 
                              handleClear={handleClear} 
                          />
                        </Grid>
                    </Grid>
                    <Grid item xs={3} container>
                    <Grid item xs={12}>
                        <TooltipTextField
                            label="Don’t forget to add an apt. or suite number"
                            placeholder = "Address line 2 apt # suite #"
                            InputLabelProps={{ shrink: true }}
                            id="addressLine2"
                            onChange={(event) => handleServiceTypeRequestTranslation(event)}
                            value={
                                serviceTypeRequestTranslation?.addressLine2
                                    ? serviceTypeRequestTranslation?.addressLine2
                                    : ''
                            }
                        />
                        </Grid>
                    </Grid>
                    <Grid item xs={3}>
                    <Grid item xs={12}>
                        <TooltipTextField
                            label="Facility Name"
                            id="facilityName"
                            onChange={(event) => handleServiceTypeRequestTranslation(event)}
                            value={
                                serviceTypeRequestTranslation?.facilityName
                                    ? serviceTypeRequestTranslation?.facilityName
                                    : ''
                            }
                        />
                        </Grid>
                    </Grid>
                    <Grid item xs={3}>
                        {/* EMPTY SPACE */}
                    </Grid>
                    {/* Row 4 */}
                    <Grid item xs={3} container>
                        <Grid item xs={12}>
                            <TooltipTextField
                                type="select"
                                formControlClass={classes.selectInput}
                                label="Language"
                                inputLabelId="demo-simple-select-label"
                                id="languageId"
                                name="languageId"
                                key={serviceTypeRequestTranslation.languageId}
                                value={
                                    serviceTypeRequestTranslation.languageId ||
                                    serviceTypeRequestTranslation.languageId === 0
                                        ? serviceTypeRequestTranslation.languageId
                                        : ''
                                }
                                onChange={(event) => handleServiceTypeRequestTranslation(event)}
                                zeroIsValid
                                required
                                error={!!props.errors?.language}
                            >
                                {languages.map((language, index) => {
                                    return (
                                        <MenuItem key={language.languageId} value={language.languageId}>
                                            {language.languageName}
                                        </MenuItem>
                                    );
                                })}
                            </TooltipTextField>
                            <FormHelperText>{props?.errors?.language}</FormHelperText>
                        </Grid>
                    </Grid>
                    <Grid item xs={3} container>
                        <Grid item xs={12}>
                            <TooltipTextField
                                label="Other Language"
                                id="otherLanguage"
                                onChange={(event) => handleServiceTypeRequestTranslation(event)}
                                disabled={
                                    !!serviceTypeRequestTranslation.languageId ||
                                    serviceTypeRequestTranslation.languageId !== 0
                                }
                                value={
                                    serviceTypeRequestTranslation?.otherLanguage
                                        ? serviceTypeRequestTranslation?.otherLanguage
                                        : ''
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={3} container>
                        <Grid item xs={12}>
                            <TooltipTextField
                                type="select"
                                formControlClass={classes.selectInput}
                                label="Interpretation Type"
                                inputLabelId="demo-simple-select-label"
                                id="interpretationTypeId"
                                name="interpretationTypeId"
                                key={serviceTypeRequestTranslation?.interpretationTypeId}
                                value={serviceTypeRequestTranslation?.interpretationTypeId}
                                onChange={(event) => handleServiceTypeRequestTranslation(event)}
                            >
                                {props.interpretationTypes?.map((interpretationType, index) => {
                                    return (
                                        <MenuItem
                                            key={interpretationType.interpretationTypeId}
                                            value={interpretationType.interpretationTypeId}
                                        >
                                            {interpretationType.interpretationTypeName}
                                        </MenuItem>
                                    );
                                })}
                            </TooltipTextField>
                        </Grid>
                    </Grid>                                      
                    <Grid item xs={12}>
                        <FormControl className={classes.selectInput}>
                            <TooltipTextField
                                maxRows={4}
                                multiline={true}
                                label="Special Instructions"
                                id="specialInstructions"
                                name="specialInstructions"
                                onChange={(event) => handleServiceTypeRequestTranslation(event)}
                                defaultValue={
                                    serviceTypeRequestTranslation?.specialInstructions
                                        ? serviceTypeRequestTranslation?.specialInstructions
                                        : ''
                                }
                                inputProps={{ maxLength: 500 }}
                                helperText={`${
                                    counterState?.specialInstructions ? counterState?.specialInstructions.length : 0
                                }/${500}`}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            ) : (
                <EmptyDataLabel></EmptyDataLabel>
            )}
        </Fragment>
    );
};
